<template>
  <b-container fluid>
    <b-row class="row text-white bg-kpmg-blue">
      <b-col align-self="center" cols="2" class="text-center" align-v="center">
        <h1 justify="center" align="center" class="kstyle title justify-content-md-center">Initiatives</h1>
        <transition name="fade">
          <div :style="style" v-if="landing">
            <nav class="navbar navbar-expand-lg navbar-light bg-white fixed-top justify-content-between navbar-top" style="height: 80px;">
             <img :src="$config.BASE_URL + '/lg.jpg'" width="107.5" height="43" />
            </nav>
            <h1 class="kstyle title justify-content-md-center" style="margin-top: 100px;">Initiatives</h1>
            <i class="fa fa-spinner fa-pulse fa-lg fa-fw" style="font-size: 6em; margin-top: 100px;"></i>
            <!-- <div style="font-size: 1.5em; margin-top: 100px;">loaded: {{rows.length}} initiatives</div> -->
          </div>
        </transition>
      </b-col>
      <b-col cols="8" align-self="center" align-v="center" class="search justify-content-md-center">
        <b-container>
          <b-row>
            <b-col>
            <h2 class="kstyle title">{{heading}}</h2>
            <b-input-group>
                <b-form-input v-model="filter" placeholder="Type to Search" />
                <b-btn :disabled="!filter" @click="filter = ''">clear</b-btn>
            </b-input-group>
            </b-col>
          </b-row>
        </b-container>
      </b-col>
    </b-row>
    <b-row class="mb-4 text-white bg-kpmg-blue" v-if="!loading && !permissions.read">
      <b-col>
        <h3>You don't have permissions for this page</h3>
      </b-col>
    </b-row>
    <b-row class="mb-4 text-white bg-kpmg-blue" v-if="!loading && permissions.read">
      <b-col>
        <b-container>
          <b-row class="mb-2">
            <b-col align-self="center" align-v="center">
              <b-form inline v-if="$config.BUILD === 'full'">
                <strong>Key datasets (presets)</strong>
                <b-button class="mr-2 ml-2" v-on:click="showESGReporting">ESG Reporting</b-button>
                <b-button class="mr-2" v-on:click="showESGTax">ESG Tax</b-button>
              </b-form>
            </b-col>
          </b-row>
          <b-row class="mb-2">
              <b-col align-self="center" class="cols text-center" align-v="center">
                <b-form inline>
                  <b-dropdown id="initiatives-filters-dropdown" text="Filters" class="mr-2" no-flip>
                    <b-dropdown-item-button v-b-toggle.sidebar-datasets>Datasets <b-badge variant="primary" v-if="datasetSelected.length < datasetOptions.length">{{datasetSelected.length}} of {{datasetOptions.length}}</b-badge></b-dropdown-item-button>
                    <b-dropdown-item-button v-b-toggle.sidebar-countries>Countries <b-badge variant="primary" v-if="countrySelected.length < countriesOptionsFilter.length">{{countrySelected.length}} of {{countriesOptionsFilter.length}}</b-badge></b-dropdown-item-button>
                    <b-dropdown-item-button v-b-toggle.sidebar-policy>Policy <b-badge variant="primary" v-if="policiesSelected.length > 0">{{policiesSelected.length}} of {{policiesOptions.length}}</b-badge></b-dropdown-item-button>
                    <b-dropdown-item-button v-b-toggle.sidebar-status>Status <b-badge variant="primary" v-if="statusSelected.length < statusOptionsFilter.length">{{statusSelected.length}} of {{statusOptionsFilter.length}}</b-badge></b-dropdown-item-button>
                    <b-dropdown-item-button v-b-toggle.sidebar-experts>Specialists <b-badge variant="primary" v-if="expertsSelected.length>0">{{expertsSelected.length}} of {{expertsOptionsFilter.length}}</b-badge></b-dropdown-item-button>
                    <b-dropdown-item-button v-b-toggle.sidebar-topics>Topics <b-badge variant="primary" v-if="topicSelected.length>0">{{topicSelected.length}} of {{topicOptionsFilter.length}}</b-badge></b-dropdown-item-button>
                    <b-dropdown-item-button v-b-toggle.sidebar-entites>Entities <b-badge variant="primary" v-if="entitySelected.length>0">{{entitySelected.length}} of {{entityOptionsFilter.length}}</b-badge></b-dropdown-item-button>
                    <b-dropdown-item-button v-b-toggle.sidebar-frameworks>Frameworks <b-badge variant="primary" v-if="reportingframeworkSelected.length>0">{{reportingframeworkSelected.length}} of {{reportingframeworkOptionsFilter.length}}</b-badge></b-dropdown-item-button>
                    <b-dropdown-item-button v-b-toggle.sidebar-regulators>Regulators <b-badge variant="primary" v-if="regulatorSelected.length>0">{{regulatorSelected.length}} of {{regulatorOptionsFilter.length}}</b-badge></b-dropdown-item-button>
                    <b-dropdown-item-button v-b-toggle.sidebar-mandatory>Mandatory <b-badge variant="primary" v-if="mandatorySelected.length > 0">{{mandatorySelected.length}} of {{mandatoryOptions.length}}</b-badge></b-dropdown-item-button>
                    <b-dropdown-item-button v-if="$config.BUILD === 'full'" v-b-toggle.sidebar-workflow>Workflow <b-badge variant="primary" v-if="workflowSelected.length > 0">{{workflowSelected.length}} of {{workflowOptions.length}}</b-badge></b-dropdown-item-button>
                    <b-dropdown-item-button v-b-toggle.sidebar-assurance>Assurance <b-badge variant="primary" v-if="assuranceSelected.length < assuranceOptions.length">{{assuranceSelected.length}} of {{assuranceOptions.length}}</b-badge></b-dropdown-item-button>
                    <b-dropdown-item-button v-b-toggle.sidebar-reportingfrequency>Reporting frequency <b-badge variant="primary" v-if="reportingfrequencySelected.length < reportingfrequencyOptions.length">{{reportingfrequencySelected.length}} of {{reportingfrequencyOptions.length}}</b-badge></b-dropdown-item-button>
                    <b-dropdown-divider></b-dropdown-divider>
                    <b-dropdown-item-button @click="showAll"><strong>show all</strong></b-dropdown-item-button>
                  </b-dropdown>
                  <b-btn class="mr-2" v-if="$config.BUILD === 'full'" @click="reload">Reload data</b-btn>
                  <b-form-select class="mr-2" v-model="viewSelected" :options="viewOptions"></b-form-select>
                  <b-btn class="mr-2" v-if="permissions.add" variant="secondary" :to="{ name: 'FormNew', params: {model: 'impactinitiative' }}">add initiative</b-btn>
                  <b-btn v-if="$config.BUILD === 'full'" class="mr-2" variant="secondary" :to="{ name: 'ImpactReporting'}">generate reports</b-btn>
                </b-form>
                  <b-sidebar id="sidebar-reportingfrequency" title="Reporting frequency" backdrop backdrop-variant="transparent" shadow>
                    <div class="px-3 py-2">
                      <b-input-group>
                      </b-input-group>
                        <b-button-group b-button-group class="mt-3">
                          <b-button variant="outline-primary" class="text-center" v-on:click="setReportingfrequencySelected('all')">all</b-button>
                          <b-button variant="outline-primary" class="text-center" v-on:click="setReportingfrequencySelected('none')">clear</b-button>
                        </b-button-group>
                      <b-card class="m-3">
                      <b-form-checkbox-group
                              v-model="reportingfrequencySelected"
                              :options="reportingfrequencyOptions"
                              button-variant="light"
                              size="md"
                              class="d-flex flex-column mx-auto text-left"
                              switches
                        ></b-form-checkbox-group>
                      </b-card>
                    </div>
                  </b-sidebar>
                  <b-sidebar id="sidebar-assurance" title="Assurance" backdrop backdrop-variant="transparent" shadow>
                    <div class="px-3 py-2">
                      <b-input-group>
                      </b-input-group>
                        <b-button-group b-button-group class="mt-3">
                          <b-button variant="outline-primary" class="text-center" v-on:click="setAssuranceSelected('all')">all</b-button>
                          <b-button variant="outline-primary" class="text-center" v-on:click="setAssuranceSelected('none')">clear</b-button>
                        </b-button-group>
                      <b-card class="m-3">
                      <b-form-checkbox-group
                              v-model="assuranceSelected"
                              :options="assuranceOptions"
                              button-variant="light"
                              size="md"
                              class="d-flex flex-column mx-auto text-left"
                              switches
                        ></b-form-checkbox-group>
                      </b-card>
                    </div>
                  </b-sidebar>
                  <b-sidebar id="sidebar-datasets" title="Datasets" backdrop backdrop-variant="transparent" shadow>
                    <div class="px-3 py-2">
                      <b-input-group>
                      </b-input-group>
                        <b-button-group b-button-group class="mt-3">
                          <b-button variant="outline-primary" class="text-center" v-on:click="setDatasetSelected('all')">all</b-button>
                          <b-button variant="outline-primary" class="text-center" v-on:click="setDatasetSelected('none')">clear</b-button>
                        </b-button-group>
                      <b-card class="m-3">
                      <b-form-checkbox-group
                              v-model="datasetSelected"
                              :options="datasetOptions"
                              button-variant="light"
                              size="md"
                              class="d-flex flex-column mx-auto text-left"
                              switches
                        ></b-form-checkbox-group>
                      </b-card>
                    </div>
                  </b-sidebar>
                  <b-sidebar id="sidebar-policy" title="Policies" backdrop backdrop-variant="transparent" shadow>
                    <div class="px-3 py-2">
                        <b-button-group b-button-group class="mt-3">
                          <b-button variant="outline-primary" class="text-center" v-on:click="setPolicySelected('all')">all</b-button>
                          <b-button variant="outline-primary" class="text-center" v-on:click="setPolicySelected('none')">none</b-button>
                        </b-button-group>
                      <b-card class="m-3">
                      <b-form-checkbox-group
                              v-model="policiesSelected"
                              :options="policiesOptions"
                              button-variant="light"
                              size="md"
                              class="d-flex flex-column mx-auto text-left"
                              switches
                        ></b-form-checkbox-group>
                      </b-card>
                    </div>
                  </b-sidebar>
                  <b-sidebar id="sidebar-status" title="Status" backdrop backdrop-variant="transparent" shadow>
                    <div class="px-3 py-2">
                      <b-input-group>
                        <b-form-input type="search" v-model="filterStatus" placeholder="Type to Search" />
                        <b-btn :disabled="!filterStatus" @click="filterStatus = ''">clear</b-btn>
                      </b-input-group>
                        <b-button-group b-button-group class="mt-3">
                          <b-button variant="outline-primary" class="text-center" v-on:click="setStatusSelected('all')">all</b-button>
                          <b-button variant="outline-primary" class="text-center" v-on:click="setStatusSelected('none')">none</b-button>
                        </b-button-group>
                      <b-card class="m-3">
                      <b-form-checkbox-group
                              v-model="statusSelected"
                              :options="statusOptionsFilter"
                              button-variant="light"
                              size="md"
                              class="d-flex flex-column mx-auto text-left"
                              switches
                        ></b-form-checkbox-group>
                      </b-card>
                    </div>
                  </b-sidebar>
                  <b-sidebar id="sidebar-experts" title="Experts" backdrop backdrop-variant="transparent" shadow>
                    <div class="px-3 py-2">
                      <b-input-group>
                        <b-form-input type="search" v-model="filterExperts" placeholder="Type to Search" />
                        <b-btn :disabled="!filterExperts" @click="filterExperts = ''">clear</b-btn>
                      </b-input-group>
                        <b-button-group b-button-group class="mt-3">
                          <b-button variant="outline-primary" class="text-center" v-on:click="setExpertSelected('all')">all</b-button>
                          <b-button variant="outline-primary" class="text-center" v-on:click="setExpertSelected('none')">no filter</b-button>
                        </b-button-group>
                      <b-card class="m-3">
                      <b-form-checkbox-group
                              v-model="expertsSelected"
                              :options="expertsOptionsFilter"
                              button-variant="light"
                              size="md"
                              class="d-flex flex-column mx-auto text-left"
                              switches
                        ></b-form-checkbox-group>
                      </b-card>
                    </div>
                  </b-sidebar>
                  <b-sidebar id="sidebar-topics" title="Topics" backdrop backdrop-variant="transparent" shadow>
                    <div class="px-3 py-2">
                      <b-input-group>
                        <b-form-input type="search" v-model="filterTopics" placeholder="Type to Search" />
                        <b-btn :disabled="!filterTopics" @click="filterTopics = ''">clear</b-btn>
                      </b-input-group>
                        <b-button-group b-button-group class="mt-3">
                          <b-button variant="outline-primary" class="text-center" v-on:click="setTopicSelected('all')">all</b-button>
                          <b-button variant="outline-primary" class="text-center" v-on:click="setTopicSelected('none')">no filter</b-button>
                        </b-button-group>
                      <b-card class="m-3">
                      <b-form-checkbox-group
                              v-model="topicSelected"
                              :options="topicOptionsFilter"
                              button-variant="light"
                              size="md"
                              class="d-flex flex-column mx-auto text-left"
                              switches
                        ></b-form-checkbox-group>
                      </b-card>
                    </div>
                  </b-sidebar>
                  <b-sidebar id="sidebar-entites" title="Entites" backdrop backdrop-variant="transparent" shadow>
                    <div class="px-3 py-2">
                      <b-input-group>
                        <b-form-input type="search" v-model="filterEntities" placeholder="Type to Search" />
                        <b-btn :disabled="!filterEntities" @click="filterEntities = ''">clear</b-btn>
                      </b-input-group>
                        <b-button-group b-button-group class="mt-3">
                          <b-button variant="outline-primary" class="text-center" v-on:click="setEntitySelected('all')">all</b-button>
                          <b-button variant="outline-primary" class="text-center" v-on:click="setEntitySelected('none')">no filter</b-button>
                        </b-button-group>
                      <b-card class="m-3">
                      <b-form-checkbox-group
                              v-model="entitySelected"
                              :options="entityOptionsFilter"
                              button-variant="light"
                              size="md"
                              class="d-flex flex-column mx-auto text-left"
                              switches
                        ></b-form-checkbox-group>
                      </b-card>
                    </div>
                  </b-sidebar>
                  <b-sidebar id="sidebar-frameworks" title="Frameworks" backdrop backdrop-variant="transparent" shadow>
                    <div class="px-3 py-2">
                      <b-input-group>
                        <b-form-input type="search" v-model="filterReportingframeworks" placeholder="Type to Search" />
                        <b-btn :disabled="!filterReportingframeworks" @click="filterReportingframeworks = ''">clear</b-btn>
                      </b-input-group>
                        <b-button-group b-button-group class="mt-3">
                          <b-button variant="outline-primary" class="text-center" v-on:click="setReportingframeworkSelected('all')">all</b-button>
                          <b-button variant="outline-primary" class="text-center" v-on:click="setReportingframeworkSelected('none')">no filter</b-button>
                        </b-button-group>
                      <b-card class="m-3">
                      <b-form-checkbox-group
                              v-model="reportingframeworkSelected"
                              :options="reportingframeworkOptionsFilter"
                              button-variant="light"
                              size="md"
                              class="d-flex flex-column mx-auto text-left"
                              switches
                        ></b-form-checkbox-group>
                      </b-card>
                    </div>
                  </b-sidebar>
                  <b-sidebar id="sidebar-regulators" title="Regulators" backdrop backdrop-variant="transparent" shadow>
                    <div class="px-3 py-2">
                      <b-input-group>
                        <b-form-input type="search" v-model="filterRegulators" placeholder="Type to Search" />
                        <b-btn :disabled="!filterRegulators" @click="filterRegulators = ''">clear</b-btn>
                      </b-input-group>
                        <b-button-group b-button-group class="mt-3">
                          <b-button variant="outline-primary" class="text-center" v-on:click="setRegulatorSelected('all')">all</b-button>
                          <b-button variant="outline-primary" class="text-center" v-on:click="setRegulatorSelected('none')">no filter</b-button>
                        </b-button-group>
                      <b-card class="m-3">
                      <b-form-checkbox-group
                              v-model="regulatorSelected"
                              :options="regulatorOptionsFilter"
                              button-variant="light"
                              size="md"
                              class="d-flex flex-column mx-auto text-left"
                              switches
                        ></b-form-checkbox-group>
                      </b-card>
                    </div>
                  </b-sidebar>
                  <b-sidebar id="sidebar-countries" title="Countries" backdrop backdrop-variant="transparent" shadow>
                    <div class="px-3 py-2">
                      <b-input-group>
                        <b-form-input type="search" v-model="filterCountries" placeholder="Type to Search" />
                        <b-btn :disabled="!filterCountries" @click="filterCountries = ''">clear</b-btn>
                      </b-input-group>
                        <b-button-group b-button-group class="mt-3">
                          <b-button variant="outline-primary" class="text-center" v-on:click="setCountriesSelected('all')">all</b-button>
                          <b-button variant="outline-primary" class="text-center" v-on:click="setCountriesSelected('none')">none</b-button>
                        </b-button-group>
                      <b-card class="m-3">
                      <b-form-checkbox-group
                              v-model="countrySelected"
                              :options="countriesOptionsFilter"
                              button-variant="light"
                              size="md"
                              class="d-flex flex-column mx-auto text-left"
                              switches
                        ></b-form-checkbox-group>
                      </b-card>
                    </div>
                  </b-sidebar>
                  <b-sidebar id="sidebar-mandatory" title="Mandatory" backdrop backdrop-variant="transparent" shadow>
                    <div class="px-3 py-2">
                      <b-card class="m-3">
                      <b-form-checkbox-group
                              v-model="mandatorySelected"
                              :options="mandatoryOptions"
                              button-variant="light"
                              size="md"
                              class="d-flex flex-column mx-auto text-left"
                              switches
                        ></b-form-checkbox-group>
                      </b-card>
                    </div>
                  </b-sidebar>
                  <b-sidebar id="sidebar-workflow" title="Workflow" backdrop backdrop-variant="transparent" shadow>
                    <div class="px-3 py-2">
                      <b-card class="m-3">
                      <b-form-checkbox-group
                              v-model="workflowSelected"
                              :options="workflowOptions"
                              button-variant="light"
                              size="md"
                              class="d-flex flex-column mx-auto text-left"
                              switches
                        ></b-form-checkbox-group>
                      </b-card>
                    </div>
                  </b-sidebar>
                </b-col>
          </b-row>
          <b-row class="mb-4">
            <b-col align-self="center" class="cols text-center" align-v="center">
              <b-form inline>
              </b-form>
            </b-col>
          </b-row>
        </b-container>
      </b-col>
    </b-row>
    <b-row v-if="!loading">
      <b-col>
        <b-container>
          <b-row v-if="disclaimer && $config.BUILD === 'full'">
            <b-col>
              <b-card class="my-3">
                <div class="mb-3">
                  <h2>Risk disclaimer</h2>
                  <div class="mb-3">
                    <strong>This data is for KPMG internal use only. Engagement Leaders are solely responsible for data provided to clients and they
                    need to perform appropriate review and quality control before providing any data to clients.</strong>
                    <b-btn class="mr-2 ml-2" @click="disclaimer=false">OK</b-btn>
                  </div>
                </div>
              </b-card>
            </b-col>
          </b-row>
          <b-row v-if="viewSelected==='list' || viewSelected==='simple'">
            <b-col>
              <b-pagination
                inline
                v-model="currentPage"
                :total-rows="showRows.length"
                :per-page="perPage"
                class="mt-3"
              ></b-pagination>
            </b-col>
            <b-col class="mt-4 text-right" justify="center" align="center">
                showing {{showRows.length}} out of {{rows.length}}
            </b-col>
          </b-row>
          <b-row v-if="viewSelected==='list'">
            <b-col>
              <b-card class="mb-3" v-for="row in showRowsPage" :key="row.id">
                <impact-initiative
                  :item="row"
                  :username="user.username"
                />
              </b-card>
            </b-col>
          </b-row>
          <b-row v-if="viewSelected==='map'">
            <b-col>
              <vue-datamaps
              :data="showCountries"
                @showcountry="showCountry"
              />
              <div>
                <h2>{{country}}</h2>
                <div v-if="mapRows.length > 0">
                  <b-card class="mb-3" v-for="row in mapRows" :key="row.id">
                    <impact-initiative
                      :item="row"
                      :username="user.username"
                    />
                  </b-card>
                </div>
                <div v-if="country != '' && mapRows.length === 0">There are no records for this country</div>
              </div>
            </b-col>
          </b-row>
          <b-row v-if="viewSelected==='simple'">
            <b-col>
              <b-card class="mb-3">
                <div class="mb-2" v-for="row in showRowsPage" :key="row.id">
                  <router-link :to="{ name: 'ImpactInitiative', params: { id: row.id }}">{{row.name}}</router-link>
                </div>
              </b-card>
            </b-col>
          </b-row>
          <b-row v-if="viewSelected==='table'">
            <b-col>
              <b-card class="mb-3">
                <b-table style="font-size: 12px; cursor: pointer;" striped hover :items="showRows" :fields="fieldsTable" @row-clicked="showInitiative"></b-table>
              </b-card>
            </b-col>
          </b-row>
          <b-row v-if="viewSelected==='grouped'">
            <b-col>
              <b-card>
                <b-form-select v-model="groupSelected" :options="groupsOptions"></b-form-select>
              </b-card>
              <b-card class="my-3">
                <div class="mb-3" v-for="row in showRowsGrouped" :key="row.id">
                  <h5>{{row.group}}</h5>
                  <div class="mb-3" v-for="initiative in row.initiatives" :key="initiative.id">
                    <router-link :to="{ name: 'ImpactInitiative', params: { id: initiative.id }}">{{initiative.name}}</router-link>
                  </div>
                </div>
              </b-card>
            </b-col>
          </b-row>
          <b-row v-if="viewSelected==='list' || viewSelected==='simple'">
            <b-col>
              <b-pagination
                v-model="currentPage"
                :total-rows="showRows.length"
              :per-page="perPage"
              ></b-pagination>
            </b-col>
          </b-row>
        </b-container>
      </b-col>
    </b-row>
    <b-row v-if="viewSelected==='matrix'">
      <b-col>
        <div class="my-4">
          <matrix
            :rows="showRows"
            :dictionaries="dictionaries"
            route="ImpactInitiative"
          ></matrix>
        </div>
      </b-col>
    </b-row>
    <b-row v-if="!loading && viewSelected==='visuals'">
      <b-col>
        <div class="my-4">
          <dashboard
            :rows="showRows"
          ></dashboard>
        </div>
      </b-col>
    </b-row>
    <b-row v-if="viewSelected==='visuals'">
      <b-col>
        <b-pagination
          v-model="currentPage"
          :total-rows="showRows.length"
        :per-page="perPage"
        ></b-pagination>
      </b-col>
    </b-row>
    <b-row v-if="viewSelected==='visuals'">
      <b-col>
        <b-card class="mb-3">
          <div class="mb-1" v-for="row in showRowsPage" :key="row.id">
            <router-link :to="{ name: 'ImpactInitiative', params: { id: row.id }}">{{row.name}}</router-link>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <b-row v-if="viewSelected==='visuals'">
      <b-col>
        <b-pagination
          v-model="currentPage"
          :total-rows="showRows.length"
        :per-page="perPage"
        ></b-pagination>
      </b-col>
    </b-row>
    <b-row v-if="viewSelected==='timeline'">
      <b-col>
        <b-card class="mb-3">
          <initiatives-timeline :rows="showRows" />
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import _ from 'lodash'
import ac from '@/libs/accesscontrol'
import countries from '@/libs/countries.js'
import workflow from '@/workflows/initiative.js'

import Dashboard from '@/components/ImpactDashboard.vue'
import ImpactInitiative from '@/components/ImpactInitiative.vue'
import InitiativesTimeline from '@/components/initiatives/Timeline.vue'
import Matrix from '@/components/Matrix.vue'
import VueDatamaps from '@/components/datamaps/Datamaps.vue'

export default {
  name: 'Initiatives',
  components: {
    Dashboard,
    ImpactInitiative,
    InitiativesTimeline,
    Matrix,
    VueDatamaps
  },
  computed: {
    assuranceOptions: function () {
      let sub = []
      _.each(this.rows, row => {
        if (row.assurance_id) {
          sub.push({ value: row.assurance.id, text: row.assurance.name })
        }
      })
      sub = _.uniqBy(sub, 'value')
      sub = [{ value: null, text: 'none' }].concat(sub)
      return sub
    },
    assuranceSelected: {
      get () {
        return this.$store.state.assuranceSelected
      },
      set (payload) {
        this.$store.commit('setAssuranceSelected', payload)
      }
    },
    countriesOptionsFilter: function () {
      if (this.rows.length > 0) {
        let options = this.rows.map(x => {
          if (x.country !== null) {
            return { value: x.country, text: x.country }
          } else {
            return { value: null, text: 'no country' }
          }
        })
        options = _.uniqBy(options, 'value')
        options = _.orderBy(options, ['text'])
        const result = _.concat([], options)
        if (this.filterCountries.length > 2) {
          let filtered = result.filter(item => item.text.toUpperCase().includes(this.filterCountries.toUpperCase()))
          return filtered
        } else {
          return result
        }
      } else {
        return []
      }
    },
    countrySelected: {
      get () {
        return this.$store.state.countrySelected
      },
      set (payload) {
        this.$store.commit('setCountrySelected', payload)
      }
    },
    datasetOptions: function () {
      let sub = []
      _.each(this.rows, row => {
        if (row.faqproject_id) {
          sub.push({ value: row.faqproject.id, text: row.faqproject.name })
        }
      })
      sub = _.uniqBy(sub, 'value')
      sub = [{ value: null, text: 'none' }].concat(sub)
      return sub
    },
    datasetSelected: {
      get () {
        return this.$store.state.datasetSelected
      },
      set (payload) {
        this.$store.commit('setDatasetSelected', payload)
      }
    },
    entityOptionsFilter: function () {
      if (this.filterEntities.length > 2) {
        let filtered = this.entityOptions.filter(item => item.text.toUpperCase().includes(this.filterEntities.toUpperCase()))
        return filtered
      } else {
        return this.entityOptions
      }
    },
    entitySelected: {
      get () {
        return this.$store.state.entitySelected
      },
      set (payload) {
        this.$store.commit('setEntitySelected', payload)
      }
    },
    expertsSelected: {
      get () {
        return this.$store.state.expertsSelected
      },
      set (payload) {
        this.$store.commit('setExpertsSelected', payload)
      }
    },
    heading: function () {
      let result
      if (this.datasetSelected.length === 1) {
        const dataset = this.datasetOptions.find(x => x.value === this.datasetSelected[0])
        result = dataset.text
      }
      return result
    },
    mandatorySelected: {
      get () {
        return this.$store.state.mandatorySelected
      },
      set (payload) {
        this.$store.commit('setMandatorySelected', payload)
      }
    },
    policiesOptions: function () {
      let sub = []
      _.each(this.rows, row => {
        if (row.impactpolicy_id) {
          sub.push({ value: row.impactpolicy.id, text: row.impactpolicy.name })
        }
      })
      sub = _.sortBy(_.uniqBy(sub, 'value'), ['text'])
      sub = [{ value: null, text: 'none' }].concat(sub)
      return sub
    },
    policiesSelected: {
      get () {
        return this.$store.state.policiesSelected
      },
      set (payload) {
        this.$store.commit('setPoliciesSelected', payload)
      }
    },
    regulatorOptionsFilter: function () {
      if (this.filterRegulators.length > 2) {
        let filtered = this.regulatorOptions.filter(item => item.text.toUpperCase().includes(this.filterRegulators.toUpperCase()))
        return filtered
      } else {
        return this.regulatorOptions
      }
    },
    reportingframeworkOptionsFilter: function () {
      if (this.filterReportingframeworks.length > 2) {
        let filtered = this.reportingframeworkOptions.filter(item => item.text.toUpperCase().includes(this.filterReportingframeworks.toUpperCase()))
        return filtered
      } else {
        return this.reportingframeworkOptions
      }
    },
    reportingfrequencyOptions: function () {
      let sub = []
      _.each(this.rows, row => {
        if (row.reportingfrequency_id) {
          sub.push({ value: row.reportingfrequency.id, text: row.reportingfrequency.name })
        }
      })
      sub = _.uniqBy(sub, 'value')
      sub = [{ value: null, text: 'none' }].concat(sub)
      return sub
    },
    reportingfrequencySelected: {
      get () {
        return this.$store.state.reportingfrequencySelected
      },
      set (payload) {
        this.$store.commit('setReportingfrequencySelected', payload)
      }
    },
    topicOptionsFilter: function () {
      if (this.filterTopics.length > 2) {
        let filtered = this.topicOptions.filter(item => item.text.toUpperCase().includes(this.filterTopics.toUpperCase()))
        return filtered
      } else {
        return this.topicOptions
      }
    },
    expertsOptionsFilter: function () {
      if (this.filterExperts.length > 2) {
        let filtered = this.expertsOptions.filter(item => item.text.toUpperCase().includes(this.filterExperts.toUpperCase()))
        return filtered
      } else {
        return this.expertsOptions
      }
    },
    perPage: function () {
      let result = 20
      if (this.viewSelected === 'simple') {
        result = 100
      }
      return result
    },
    regulatorSelected: {
      get () {
        return this.$store.state.regulatorSelected
      },
      set (payload) {
        this.$store.commit('setRegulatorSelected', payload)
      }
    },
    reportingframeworkSelected: {
      get () {
        return this.$store.state.reportingframeworkSelected
      },
      set (payload) {
        this.$store.commit('setReportingframeworkSelected', payload)
      }
    },
    rows: {
      get () {
        return this.$store.state.initiatives
      },
      set (payload) {
        this.$store.commit('setInitiatives', payload)
      }
    },
    staticData: {
      get () {
        return this.$store.state.initiativesStaticData
      },
      set (payload) {
        this.$store.commit('setInitiativesStaticData', payload)
      }
    },
    statusOptionsFilter: function () {
      if (this.filterStatus.length > 2) {
        let filtered = this.statusOptions.filter(item => item.text.toUpperCase().includes(this.filterStatus.toUpperCase()))
        return filtered
      } else {
        return this.statusOptions
      }
    },
    statusSelected: {
      get () {
        return this.$store.state.statusSelected
      },
      set (payload) {
        this.$store.commit('setStatusSelected', payload)
      }
    },
    showCountries: function () {
      const results = {}
      const cs = this.showRows.map(x => x.country)
      cs.forEach(x => {
        const country = _.find(countries, ['country', x])
        const value = { fillKey: 'exists' }
        Object.defineProperty(results, country.alpha3, { value: value, writable: true })
      })
      return results
    },
    showRows: function () {
      let result = this.rows

      // create pseudo countries property for matrix component
      _.each(result, x => {
        x.status = x.impactstatus.name
        if (x.country) {
          x.countries = {
            name: x.country
          }
        } else {
          x.countries = {
            name: 'null'
          }
        }
      })

      let newResult = []
      _.each(this.countrySelected, row => {
        let filteredResult = []
        filteredResult = _.filter(result, ['country', row])
        _.each(filteredResult, x => {
          newResult.push(x)
        })
      })
      result = newResult

      // filter by status
      newResult = []
      _.each(this.statusSelected, row => {
        let filteredResult = []
        filteredResult = _.filter(result, ['status_id', row])
        _.each(filteredResult, x => {
          newResult.push(x)
        })
      })
      result = newResult

      // filter by workflow
      if (this.workflowSelected.length > 0) {
        newResult = []
        _.each(this.workflowSelected, id => {
          let filteredResult = []
          filteredResult = _.filter(result, ['workflow_id', id])
          _.each(filteredResult, x => {
            newResult.push(x)
          })
        })
        result = newResult
      }

      // filter by mandatory
      if (this.mandatorySelected.length > 0) {
        newResult = []
        _.each(this.mandatorySelected, field => {
          let filteredResult = []
          filteredResult = _.filter(result, [field, true])
          _.each(filteredResult, x => {
            newResult.push(x)
          })
        })
        const newResultUniq = _.uniqBy(newResult, 'id')
        result = newResultUniq
      }

      // filter by assurance
      if (this.assuranceSelected.length > 0 && this.assuranceSelected.length < this.assuranceOptions.length) {
        newResult = []
        _.each(this.assuranceSelected, row => {
          let filteredResult = []
          filteredResult = _.filter(result, ['assurance_id', row])
          _.each(filteredResult, x => {
            newResult.push(x)
          })
        })
        result = newResult
      }

      // filter by reporting frequency
      if (this.reportingfrequencySelected.length > 0 && this.reportingfrequencySelected.length < this.reportingfrequencyOptions.length) {
        newResult = []
        _.each(this.reportingfrequencySelected, row => {
          let filteredResult = []
          filteredResult = _.filter(result, ['reportingfrequency_id', row])
          _.each(filteredResult, x => {
            newResult.push(x)
          })
        })
        result = newResult
      }

      // filter by dataset
      if (this.datasetSelected.length > 0) {
        newResult = []
        _.each(this.datasetSelected, row => {
          let filteredResult = []
          filteredResult = _.filter(result, ['faqproject_id', row])
          _.each(filteredResult, x => {
            newResult.push(x)
          })
        })
        result = newResult
      }

      // filter by policy
      if (this.policiesSelected.length > 0) {
        newResult = []
        _.each(this.policiesSelected, row => {
          let filteredResult = []
          filteredResult = _.filter(result, ['impactpolicy_id', row])
          _.each(filteredResult, x => {
            newResult.push(x)
          })
        })
        result = newResult
      }

      // filter by experts
      if (this.expertsSelected.length > 0) {
        newResult = []
        _.each(this.expertsSelected, row => {
          let filteredResult = []
          _.filter(result, x => {
            if (_.find(x.impactexperts, ['id', row])) {
              filteredResult.push(x)
            }
          })
          _.each(filteredResult, x => {
            newResult.push(x)
          })
        })
        result = newResult
      }

      // filter by regulator
      if (this.regulatorSelected.length > 0) {
        newResult = []
        _.each(this.regulatorSelected, row => {
          let filteredResult = []
          _.filter(result, x => {
            if (_.find(x.regulators, ['id', row])) {
              filteredResult.push(x)
            }
          })
          _.each(filteredResult, x => {
            newResult.push(x)
          })
        })
        result = newResult
      }

      // filter by topics
      if (this.topicSelected.length > 0) {
        newResult = []
        _.each(this.topicSelected, row => {
          let filteredResult = []
          _.filter(result, x => {
            if (_.find(x.impacttopics, ['id', row])) {
              filteredResult.push(x)
            }
          })
          _.each(filteredResult, x => {
            newResult.push(x)
          })
        })
        result = newResult
      }

      // filter by entities
      if (this.entitySelected.length > 0) {
        newResult = []
        _.each(this.entitySelected, row => {
          let filteredResult = []
          _.filter(result, x => {
            if (_.find(x.entities, ['id', row])) {
              filteredResult.push(x)
            }
          })
          _.each(filteredResult, x => {
            newResult.push(x)
          })
        })
        result = newResult
      }

      // filter by reportingframework
      if (this.reportingframeworkSelected.length > 0) {
        newResult = []
        _.each(this.reportingframeworkSelected, row => {
          let filteredResult = []
          _.filter(result, x => {
            if (_.find(x.reportingframeworks, ['id', row])) {
              filteredResult.push(x)
            }
          })
          _.each(filteredResult, x => {
            newResult.push(x)
          })
        })
        result = newResult
      }

      if (this.filter.length > 2) {
        result = result.filter(item => item.name.toUpperCase().includes(this.filter.toUpperCase()))
      }
      let uniqueResult = _.orderBy(_.uniqBy(result, 'id'), ['name'])
      this.$store.commit('setShowInitiatives', uniqueResult)
      return uniqueResult
    },
    showRowsPage: function () {
      const start = (this.currentPage - 1) * this.perPage
      const end = this.currentPage * this.perPage
      return this.showRows.slice(start, end)
    },
    showRowsByCountry: function () {
      let countries = this.showRows.map(x => x.country)
      countries = _.uniq(countries).sort()
      const result = []
      _.each(countries, x => {
        const row = {
          country: x,
          initiatives: _.filter(this.showRows, y => {
            return y.country === x
          })
        }
        result.push(row)
      })
      return result
    },
    showRowsGrouped: function () {
      const groupSelected = this.groupSelected
      const group = _.find(this.groups, ['name', groupSelected])
      const property = group.property
      let groups = []
      const result = []
      if (group.shape === 'flat') {
        groups = this.showRows.map(x => x[property])
        groups = _.uniq(groups).sort()
        _.each(groups, x => {
          const row = {
            group: x,
            initiatives: _.filter(this.showRows, y => {
              return y[property] === x
            })
          }
          result.push(row)
        })
      }
      if (group.shape === 'array') {
        _.each(this.showRows, row => {
          if (row[group.tree]) {
            if (row[group.tree].length > 0) {
              _.each(row[group.tree], x => {
                groups.push(x[property])
              })
            }
          }
        })
        groups = _.uniq(groups).sort()
        _.each(groups, x => {
          const row = {
            group: x,
            initiatives: _.filter(this.showRows, y => {
              const result = _.find(y[group.tree], z => {
                return z[property] === x
              })
              return result
            })
          }
          result.push(row)
        })
      }
      return result
    },
    topicSelected: {
      get () {
        return this.$store.state.topicSelected
      },
      set (payload) {
        this.$store.commit('setTopicSelected', payload)
      }
    },
    user: {
      get () {
        return this.$store.state.user
      }
    },
    workflowSelected: {
      get () {
        return this.$store.state.initiativeWorkflowSelected
      },
      set (payload) {
        this.$store.commit('setInitiativeWorkflowSelected', payload)
      }
    }
  },
  created: async function () {
    if (this.$config.BUILD === 'external') {
      this.permissions.read = ac.can(this.user.acgroups).readAny('initiative').granted
    } else {
      this.permissions.read = true
    }
    const image = _.sample(['fox', 'penguin', 'photographer', 'tent', 'wave', 'whale'])
    this.style = `background-image: url('/img/${image}.jpg'); background-repeat: no-repeat; background-size: cover; position: fixed; z-index: 10000; top: 66px; right: 0px; bottom: 0px; left: 0px;`
    document.title = "Initiatives"
    this.$store.commit('setBrand', 'Initiatives')
    this.$stat.log({ page: 'impact initiatives', action: 'open impact initiatives' })
    this.permissions.add = ac.can(this.user.acgroups).createAny('impactinitiative').granted
    this.permissions.reporting = ac.can(this.user.acgroups).createAny('impactreport').granted
    this.groupsOptions = this.groups.map(x => {
      return { value: x.name, text: x.name }
    })
    if (this.$route.query.c) {
      this.countrySelected = [this.$route.query.c]
    }
    await this.load()
    console.log(this.policiesOptions)
  },
  data () {
    return {
      country: '',
      currentPage: 1,
      dictionaries: [],
      disclaimer: true,
      entities: [],
      entityOptions: [],
      experts: [],
      expertsOptions: [],
      fieldsTable: [
        { key: "name", sortable: true, label: "Initiative" },
        { key: "country", sortable: true, label: "Country" },
        { key: "status", sortable: true, label: "Status" }
      ],
      filterEntities: '',
      filterExperts: '',
      filterRegulators: '',
      filterReportingframeworks: '',
      filterTopics: '',
      filterStatus: '',
      filterCountries: '',
      groupSelected: 'country',
      groups: [
        {
          name: 'country',
          property: 'country',
          shape: 'flat'
        },
        {
          name: 'entity',
          property: 'name',
          shape: 'array',
          tree: 'entities'
        },
        {
          name: 'expert',
          property: 'name',
          shape: 'array',
          tree: 'impactexperts'
        },
        {
          name: 'regulators',
          property: 'name',
          shape: 'array',
          tree: 'regulators'
        },
        {
          name: 'reporting framework',
          property: 'name',
          shape: 'array',
          tree: 'reportingframeworks'
        },
        {
          name: 'topic',
          property: 'name',
          shape: 'array',
          tree: 'impacttopics'
        }
      ],
      mandatoryOptions: [
        { value: 'mandatory', text: 'mandatory' },
        { value: 'optional', text: 'optional' },
        { value: 'comply_explain', text: 'comply or explain' }
      ],
      permissions: {
        read: false,
        add: false
      },
      groupsOptions: [],
      filter: '',
      landing: false,
      loading: true,
      mapRows: [],
      regulators: [],
      regulatorOptions: [],
      reportingframeworks: [],
      reportingframeworkOptions: [],
      statuses: [],
      statusOptions: [],
      style: '',
      topics: [],
      topicOptions: [],
      viewOptions: ['list', 'table', 'map', 'matrix', 'simple', 'grouped', 'timeline', 'visuals'],
      viewSelected: this.$route.query.v ? this.$route.query.v : 'list',
      workflowOptions: workflow.map(x => { return { value: x.id, text: x.name } })
    }
  },
  methods: {
    filtersFirstLoad: function () {
      this.filter = ''
      if (this.assuranceSelected.length === 0) {
        this.setAssuranceSelected('all')
      }
      if (this.countrySelected.length === 0) {
        this.setCountriesSelected('all')
      }
      if (this.datasetSelected.length === 0) {
        this.setDatasetSelected('all')
      }
      if (this.reportingfrequencySelected.length === 0) {
        this.setReportingfrequencySelected('all')
      }
      if (this.statusSelected.length === 0) {
        this.setStatusSelected('all')
      }
    },
    filtersReset: function () {
      this.filter = ''
      this.setAssuranceSelected('all')
      this.setDatasetSelected('all')
      this.setCountriesSelected('all')
      this.setStatusSelected('all')
      this.setExpertSelected('none')
      this.setEntitySelected('none')
      this.setMandatorySelected('none')
      this.setRegulatorSelected('none')
      this.setReportingframeworkSelected('none')
      this.setReportingfrequencySelected('all')
      this.setTopicSelected('none')
      this.setWorkflowSelected('none')
    },
    load: async function () {
      this.loading = true
      try {
        if (this.rows.length === 0) {
          await this.loadRows()
        }
        this.dictionaries = []

        let countriesData = this.rows.map(x => x.country)
        countriesData = _.uniq(countriesData)
        countriesData.sort()
        const countryDictionary = {
          name: 'countries',
          graph: 'countries',
          data: countriesData
        }
        this.dictionaries.push(countryDictionary)

        if (this.staticData === null) {
          const staticData = await this.$Amplify.API.get('cosmos', '/impact/staticdata')
          this.staticData = staticData
        }

        this.entities = this.staticData.entities
        const entitiesDictionary = {
          name: 'entities',
          graph: 'entities',
          data: this.entities.map(x => x.name)
        }
        this.dictionaries.push(entitiesDictionary)
        this.entityOptions = []
        _.each(this.entities, x => {
          this.entityOptions.push({ value: x.id, text: x.name })
        })

        this.experts = this.staticData.experts
        const expertsDictionary = {
          name: 'experts',
          graph: 'impactexperts',
          data: this.experts.map(x => x.name)
        }
        this.dictionaries.push(expertsDictionary)
        this.expertsOptions = []
        _.each(this.experts, x => {
          this.expertsOptions.push({ value: x.id, text: x.name })
        })

        this.regulators = this.staticData.regulators
        const regulatorsDictionary = {
          name: 'regulators',
          graph: 'regulators',
          data: this.regulators.map(x => x.name)
        }
        this.dictionaries.push(regulatorsDictionary)
        this.regulatorOptions = []
        _.each(this.regulators, x => {
          this.regulatorOptions.push({ value: x.id, text: x.name })
        })

        this.reportingframeworks = this.staticData.reportingframeworks
        const reportingframeworksDictionary = {
          name: 'reporting framework',
          graph: 'reportingframeworks',
          data: this.reportingframeworks.map(x => x.name)
        }
        this.dictionaries.push(reportingframeworksDictionary)
        this.reportingframeworkOptions = []
        _.each(this.reportingframeworks, x => {
          this.reportingframeworkOptions.push({ value: x.id, text: x.name })
        })

        this.statuses = this.staticData.statuses
        const statusDictionary = {
          name: 'status',
          graph: 'impactstatus',
          data: this.statuses.map(x => x.name)
        }
        this.dictionaries.push(statusDictionary)
        this.statusOptions = []
        _.each(this.statuses, x => {
          this.statusOptions.push({ value: x.id, text: x.name })
        })

        this.topics = this.staticData.topics
        const topicsDictionary = {
          name: 'topics',
          graph: 'impacttopics',
          data: this.topics.map(x => x.name)
        }
        this.dictionaries.push(topicsDictionary)
        this.topicOptions = []
        _.each(this.topics, x => {
          this.topicOptions.push({ value: x.id, text: x.name })
        })
        this.loading = false
      } catch (e) {
        this.$logger.warn('saved ERROR: ', e)
      }
      this.filtersFirstLoad()
      if (this.$route.query.p) {
        this.setPreSet(this.$route.query.p)
      }
    },
    loadRows: async function () {
      this.landing = true
      try {
        const limit = 200
        let offset = 0
        let response = []
        let page = 0
        do {
          offset = page * limit
          response = await this.$Amplify.API.get('cosmos', `/impact/initiatives/limit/${limit}/offset/${offset}`)
          const rows = _.concat(this.rows, response)
          this.rows = rows
          page++
          await this.$nextTick()
        } while (response.length === limit)
      } catch (e) {
        this.$logger.warn('saved ERROR: ', e)
      }
      this.landing = false
    },
    reload: function () {
      this.loading = true
      this.rows = []
      this.load()
    },
    setAssuranceSelected: function (button) {
      if (button === "all") {
        this.assuranceSelected = []
        _.each(this.assuranceOptions, x => {
          this.assuranceSelected.push(x.value)
        })
      }
      if (button === "none") {
        this.assuranceSelected = []
      }
    },
    setCountriesSelected: function (button) {
      if (button === "all") {
        let countrySelected = []
        _.each(this.rows, x => {
          countrySelected.push(x.country)
        })
        countrySelected = _.uniq(countrySelected)
        this.countrySelected = countrySelected
      }
      if (button === "none") {
        this.countrySelected = []
      }
    },
    setDatasetSelected: function (button) {
      if (button === "all") {
        this.datasetSelected = []
        _.each(this.datasetOptions, x => {
          this.datasetSelected.push(x.value)
        })
      }
      if (button === "none") {
        this.datasetSelected = []
      }
    },
    setEntitySelected: function (button) {
      if (button === "all") {
        this.entitySelected = []
        _.each(this.entities, x => {
          this.entitySelected.push(x.id)
        })
      }
      if (button === "none") {
        this.entitySelected = []
      }
    },
    setExpertSelected: function (button) {
      if (button === "all") {
        this.expertsSelected = []
        _.each(this.experts, x => {
          this.expertsSelected.push(x.id)
        })
      }
      if (button === "none") {
        this.expertsSelected = []
      }
    },
    setMandatorySelected: function (button) {
      if (button === "all") {
        this.mandatorySelected = []
        _.each(this.mandatoryOptions, x => {
          this.mandatorySelected.push(x.value)
        })
      }
      if (button === "none") {
        this.mandatorySelected = []
      }
    },
    setPolicySelected: function (button) {
      if (button === "all") {
        this.policiesSelected = []
        _.each(this.policiesOptions, x => {
          this.policiesSelected.push(x.value)
        })
      }
      if (button === "none") {
        this.policiesSelected = []
      }
    },
    setPreSet: function (preset) {
      if (preset === 'esgReporting') {
        this.datasetSelected = [5]
        document.title = "ESG Reporting Initiatives"
      }
      if (preset === 'esgTax') {
        this.datasetSelected = [6]
        document.title = "ESG Tax Initiatives"
      }
    },
    setRegulatorSelected: function (button) {
      if (button === "all") {
        this.regulatorSelected = []
        _.each(this.regulators, x => {
          this.regulatorSelected.push(x.id)
        })
      }
      if (button === "none") {
        this.regulatorSelected = []
      }
    },
    setReportingframeworkSelected: function (button) {
      if (button === "all") {
        this.reportingframeworkSelected = []
        _.each(this.reportingframeworks, x => {
          this.reportingframeworkSelected.push(x.id)
        })
      }
      if (button === "none") {
        this.reportingframeworkSelected = []
      }
    },
    setReportingfrequencySelected: function (button) {
      if (button === "all") {
        this.reportingfrequencySelected = []
        _.each(this.reportingfrequencyOptions, x => {
          this.reportingfrequencySelected.push(x.value)
        })
      }
      if (button === "none") {
        this.reportingfrequencySelected = []
      }
    },
    setStatusSelected: function (button) {
      if (button === "all") {
        this.statusSelected = []
        _.each(this.statuses, x => {
          this.statusSelected.push(x.id)
        })
      }
      if (button === "none") {
        this.statusSelected = []
      }
    },
    setTopicSelected: function (button) {
      if (button === "all") {
        this.topicSelected = []
        _.each(this.topics, x => {
          this.topicSelected.push(x.id)
        })
      }
      if (button === "none") {
        this.topicSelected = []
      }
    },
    setWorkflowSelected: function (button) {
      if (button === "all") {
        this.workflowSelected = []
        _.each(this.workflowOptions, x => {
          this.workflowSelected.push(x.value)
        })
      }
      if (button === "none") {
        this.workflowSelected = []
      }
    },
    showAll: function () {
      this.filtersReset()
      if (this.$route.query.p) {
        this.$router.push({ name: 'ImpactInitiatives', query: { v: this.viewSelected } })
      }
    },
    showCountry (id) {
      const country = _.find(countries, ['alpha3', id])
      this.country = country.country
      this.mapRows = _.filter(this.showRows, ['country', country.country])
      if (this.mapRows.length > 0) {
        window.scrollBy({ top: 500, left: 0, behaviour: 'smooth' })
      }
    },
    showESGReporting: function () {
      const query = {
        p: 'esgReporting',
        v: this.viewSelected
      }
      if (this.countrySelected.length === 1) {
        query.c = this.countrySelected[0]
      }
      this.$router.push({ name: 'ImpactInitiatives', query: query })
    },
    showESGTax: function () {
      const query = {
        p: 'esgTax',
        v: this.viewSelected
      }
      if (this.countrySelected.length === 1) {
        query.c = this.countrySelected[0]
      }
      this.$router.push({ name: 'ImpactInitiatives', query: query })
    },
    showInitiative: function (initiative, index, event) {
      this.$router.push({ name: 'ImpactInitiative', params: { id: initiative.id } })
    }
  },
  watch: {
    '$route': function () {
      if (this.$route.query.p) {
        this.setPreSet(this.$route.query.p)
      } else {
        document.title = "Initiatives"
      }
    },
    countrySelectedx: function () {
      if (this.countrySelected.length === 1) {
        const params = {}
        if (this.$route.query.p) {
          params.p = this.$route.query.p
        }
        params.c = this.countrySelected[0]
        params.v = this.viewSelected
        this.$router.push({ name: 'ImpactInitiatives', query: params })
      }
      if (this.countrySelected.length > 1) {
        const params = {}
        if (this.$route.query.p) {
          params.p = this.$route.query.p
        }
        params.v = this.viewSelected
        this.$router.push({ name: 'ImpactInitiatives', query: params })
      }
    },
    viewSelected: function () {
      const params = {}
      if (this.$route.query.p) {
        params.p = this.$route.query.p
      }
      if (this.$route.query.c) {
        params.c = this.$route.query.c
      }
      params.v = this.viewSelected
      this.$router.push({ name: 'ImpactInitiatives', query: params })
    }
  }
}
</script>

<style scope>
h1 {
  margin: 15px;
}
.b-sidebar {
  width: 40%;
  min-width: 320px;
}
.date-column {
  width: 100px;
}
.filter {
  max-width: 253px;
}
</style>
